import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const privacyPage: React.FC<{}> = () => (
	<PrimaryLayout>
		<SEO title="Privacy policy" />
		<Container py="40px">
			<h1> Privacy Policy </h1>
			<p>When you use our services, you&rsquo;re trusting us with your as well as your customer's information. We understand this is a big responsibility and work hard to protect your information and put you in control. We collect information, including Personal Data, for the following purposes:</p>
			<ul>
			<li>Providing and managing the Service</li>
			<li>Internal business purposes</li>
			<li>Communicating and marketing</li>
			<li>Collecting payment for the Service</li>
			<li>To understand and continuously improve our Service</li>
			</ul>
			<p>In order to provide our services to you and your customers, we collect the user&rsquo;s name, email address and phone number and upload these details into our secure servers hosted in AWS cloud. These details are used only to identify the customers and deliver the content that they need on the app.As a matter of practice, we do not disclose, trade, rent, sell or otherwise transfer Personal Data.</p>
			<p><br /><br /></p>
		</Container>		
	</PrimaryLayout>
);

export default privacyPage;
